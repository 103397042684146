import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import request from './tools/request';
import 'jquery';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from "element-ui/lib/locale/lang/en";
import {setUnit} from "./tools";
import {Toast, Dialog} from 'vant';
import '@vant/touch-emulator';
import '@/assets/style/font.css';
import animated from 'animate.css';
import * as echarts from 'echarts/core';
import {TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import 'swiper/css/swiper.css';


Vue.config.productionTip = false;
Vue.prototype.setUnit = setUnit;
Vue.prototype.$http = request;

Vue.use(ElementUI, {locale});
Vue.use(Toast).use(Dialog);
Vue.use(animated);

echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer, UniversalTransition]);
Vue.use(echarts);


new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');

