<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {};
        },
        created() {
        },
        mounted() {
        },
        methods: {}
    }
</script>

<style lang="less">
    @font-face{
        font-family: "Inter";
        src: url("./assets/fonts/Inter-Bold.ttf");
    }
    body * {
        box-sizing: border-box;
        flex-shrink: 0;
    }
    body::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    body {
        overflow-y: auto;
        // font-family: Inter,PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
    }

    #trans-tooltip {
        display: none !important;
    }
    #tip-arrow-bottom {
        display: none !important;
    }
    #tip-arrow-top {
        display: none !important;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .justify-start {
        display: flex;
        justify-content: flex-start;
    }
    .justify-center {
        display: flex;
        justify-content: center;
    }

    .justify-end {
        display: flex;
        justify-content: flex-end;
    }
    .justify-evenly {
        display: flex;
        justify-content: space-evenly;
    }
    .justify-around {
        display: flex;
        justify-content: space-around;
    }
    .justify-between {
        display: flex;
        justify-content: space-between;
    }
    .align-start {
        display: flex;
        align-items: flex-start;
    }
    .align-center {
        display: flex;
        align-items: center;
    }
    .align-end {
        display: flex;
        align-items: flex-end;
    }
    video::-webkit-media-controls {
        display: none !important;
    }
</style>
