import axios from 'axios';
import store from "../store";



const upchainok = function () {
    return new Promise((resolve, reject) => {
        axios.post(
            (
                parseInt(store.state.networkIDstring) === 223 ||
                parseInt(store.state.networkIDstring) === 212 ||
                parseInt(store.state.networkIDstring) === 22776
            ) ? "https://apit.bitsea.ink/api" : "https://api.bitsea.ink/api",
            {
                "query_api": (parseInt(store.state.networkIDstring) === 223 ? "upchainok_btwo" : "upchainok"),
                "query_args": {}
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const listpici = function () {
    return new Promise((resolve, reject) => {
        axios.post(
            (
                parseInt(store.state.networkIDstring) === 223 ||
                parseInt(store.state.networkIDstring) === 212 ||
                parseInt(store.state.networkIDstring) === 22776
            ) ? "https://apit.bitsea.ink/api" : "https://api.bitsea.ink/api",
            {
                "query_api": (parseInt(store.state.networkIDstring) === 223 ? "listpici_btwo" : "listpici"),
                "query_args": {}
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const listgoods = function (pageNum = 1, sortNum = 10, nftName, nftAddress, user) {
    return new Promise((resolve, reject) => {
        axios.post(
            (
                parseInt(store.state.networkIDstring) === 223 ||
                parseInt(store.state.networkIDstring) === 212 ||
                parseInt(store.state.networkIDstring) === 22776
            ) ? "https://apit.bitsea.ink/api" : "https://api.bitsea.ink/api",
            {
                "query_api": (parseInt(store.state.networkIDstring) === 223 ? "listgoods_btwo" : "listgoods"),
                "query_args": {
                    "page": pageNum,
                    "sort": sortNum,
                    "name": nftName,
                    "address": nftAddress,
                    "address_": user,
                }
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const listdeal = function (pageNum = 1, sortNum = 10, userAddress) {
    return new Promise((resolve, reject) => {
        axios.post(
            (
                parseInt(store.state.networkIDstring) === 223 ||
                parseInt(store.state.networkIDstring) === 212 ||
                parseInt(store.state.networkIDstring) === 22776
            ) ? "https://apit.bitsea.ink/api" : "https://api.bitsea.ink/api",
            {
                "query_api": (parseInt(store.state.networkIDstring) === 223 ? "listdeal_btwo" : "listdeal"),
                "query_args": {
                    "page": pageNum,
                    "sort": sortNum,
                    "address": userAddress,
                }
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const listcontract = function (nftAddress) {
    return new Promise((resolve, reject) => {
        axios.post(
            (
                parseInt(store.state.networkIDstring) === 223 ||
                parseInt(store.state.networkIDstring) === 212 ||
                parseInt(store.state.networkIDstring) === 22776
            ) ? "https://apit.bitsea.ink/api" : "https://api.bitsea.ink/api",
            {
                "query_api": (parseInt(store.state.networkIDstring) === 223 ? "listcontract_btwo_" : "listcontract_"),
                "query_args": {
                    "address": nftAddress,
                }
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getStatistics = function(chainId) {
    return new Promise((resolve, reject) => {
        axios.post(
            (
                parseInt(chainId) === 223 ||
                parseInt(chainId) === 212 ||
                parseInt(chainId) === 22776
            ) ? "https://apit.bitsea.ink/api" : "https://api.bitsea.ink/api",
            {
                "query_api": (parseInt(chainId) === 223 ? "bitsea_statistics_btwo" : "bitsea_statistics"),
                "query_args": {}
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getTokenHolders = function (address) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://scan-mainnet-api.bevm.io/api/v2/tokens/" + address + "/counters",
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getGbxInfo = function (address) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://scan-mainnet-api.bevm.io/api/v2/addresses/" + address,
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getUserGbxList = function (address, user, unique) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://scan-mainnet-api.bevm.io/api/v2/tokens/" + address + "/instances?holder_address_hash=" + user + "" + (parseInt(unique) > 0 ? ("&unique_token="+unique) : ""),
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getTokenDetail = function (address) {
    return new Promise((resolve, reject) => {
        axios.get(
            'https://explorer.bsquared.network/api/trpc/token.getTokenDetail?input={"json":"' + address + '"}',
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getAddressTokenBalance = function (address, user, take, cursor) {
    return new Promise((resolve, reject) => {
        axios.get(
            'https://explorer.bsquared.network/api/trpc/address.getAddressTokenBalance?input={"json":{"address":"' + user + '","tokenType":"erc721"}}',
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getAddressTokenTxList = function (address, user, take, cursor) {
    return new Promise((resolve, reject) => {
        axios.get(
            'https://explorer.bsquared.network/api/trpc/address.getAddressTokenTxList?input={"json":{"address":"' + user + '","tokenType":"erc721","take":' + take + ',"desc":true,"cursor":' + cursor + '},"meta":{"values":{}}}',
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*'
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}



const http = {
    upchainok,
    listpici,
    listgoods,
    listdeal,
    listcontract,
    getStatistics,
    getTokenHolders,
    getGbxInfo,
    getUserGbxList,
    getTokenDetail,
    getAddressTokenBalance,
    getAddressTokenTxList
}

export default http;
