import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/Mapo/TheAwakeningOfMapNft',
        },
        {
            path: '/Mapo/TheAwakeningOfMapNft',
            name: 'MapoTheAwakeningOfMapNft',
            component: () => import(/* webpackChunkName: "theAwakeningOfMapNft" */ './views/mapo/theAwakeningOfMapNft/Index.vue'),
            meta: {
                title: 'MAPOTHEAWAKENINGOFMAPNFT'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({
            path: '/Mapo/TheAwakeningOfMapNft',
        });
    } else {
        next();
    }
});

const VueRouterPush = Router.prototype.push;

Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
}

export default router;
